<template>
  <div class="step-content">
    <v-card 
      outlined
      color="transparent"
      class="faq text-left mx-n4 my-8 mt-12"
    >
      <v-list 
        flat
        color="transparent"
        class="py-0"
      >
        <v-list-item
          class="faq-item py-2"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="primary">{{ icons.mdiInformationOutline }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-medium">
              Orientações
            </v-list-item-title>
            <v-list-item-subtitle class="item-subtitle mt-2">
              No dia da instalação abordaremos os assuntos abaixo:
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="(faq, key) in faq"
          :key="key"
          class="faq-item py-1 secondary--text"
          @click="toggleHelp(true, 'activation', faq.section, faq.id)"
        >
          <v-list-item-title v-text="faq.title" />
        </v-list-item>
      </v-list>
      <!-- <v-divider class="mt-0" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="toggleHelp(true, 'activation')"
        >
          Ver mais
        </v-btn>
      </v-card-actions> -->
    </v-card>

    <slot name="quick-access" />

    <v-btn 
      text
      small
      class="help-btn"
      color="grey"
      @click="toggleHelp(true, 'signup')"
    >
      <v-icon
        small
        class="mr-4"
      >
        {{ icons.mdiHelpCircleOutline }}
      </v-icon>
      Precisa de ajuda?
    </v-btn>
  </div>
</template>

<style>

  .step-content .item-title {
    margin: 6px 0;
  }

  .step-content .item-subtitle {
    line-height: 1.4;
    text-overflow: initial;
    white-space: initial;
  }

  .step-content .calendar {
    max-width: initial;
  }
  .step-content .faq {
    max-width: initial;
    border-color: var(--v-grey-darken3) !important;
  }

  .step-content .faq .faq-item:not(:last-child) {
    border-bottom: 1px solid var(--v-grey-darken3) !important;
  }

</style>

<script>

  // Icons
  import {
    mdiHelpCircleOutline,
    mdiInformationOutline,
    mdiClose,
    mdiCalendarAlert,
    mdiCheck
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { sync, get } from 'vuex-pathify'
  import _ from 'lodash';
  const moment = require('moment');


  export default {
    name: 'Step4',

    props: {
      step: {
        type: Object,
        default: () => {}
      },
      appURL: {
        type: String,
        default: ''
      },
      refresh: {
        type: Boolean,
        default: false
      }
    },

    components: {
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiHelpCircleOutline,
        mdiInformationOutline,
        mdiClose,
        mdiCalendarAlert,
        mdiCheck
      },
      faq: [
        {
          title: 'Modelo de Remuneração',
          section: 'goal',
          id: 9,
        },
        {
          title: 'Manual do Equipamento',
          section: 'manual',
          id: null,
        },
      ],
    }),

    computed: {
      profileView: sync('app/views@profile'),
      user: sync('user'),
      status: sync('user/status'),
      toast: sync('app/toast'),
      help: sync('app/views@help'),
      help_sections: sync('help/sections'),

      stepName () {
        return this.$options.name.toLowerCase();
      },

    },

    watch: {
      refresh (b) {
        if (b) this.getCalendarSlots();
      }
    },

    methods: {
      ...services,

      openLocation (uri, target) {
        window.open(uri, target);
      },

      actionCalled () {
      },

      updateView () {

      },

    },

    created () {
    },

    mounted () {
    },

    directives: {
    },

  }
</script>
